import {ViewsCountPerDayDTO} from "./ViewsCountPerDayDTO";
import {ViewsCount} from "./ViewsCount";

export class GroupedViewsCountPerDayDTO{
    groupBy: string;
    key: string;
    label: string;
    viewsCountPerDay: ViewsCountPerDayDTO[];
    total: ViewsCount;
    countryId: number;
    serviceId: number;
    artist: string;

    constructor(json: any) {
        this.groupBy = json.groupBy;
        this.key = json.key;
        this.label = json.label;
        this.viewsCountPerDay = json.viewsCountPerDay?.map((viewsCountPerDay: any) => new ViewsCountPerDayDTO(viewsCountPerDay)) ?? [];
        this.total = new ViewsCount(json.total);
        this.countryId = json.countryId;
        this.serviceId = json.serviceId;
        this.artist = json.artist;
    }
}