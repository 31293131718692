import {ClientDTO} from "../models/ClientDTO";
import axiosInstance from "../network/AxiosInstance";

export class ClientService {
    static async findById(id: number): Promise<ClientDTO> {
        const response = await axiosInstance.get<ClientDTO>(
            `/rest/client/client/${id}`,
        );

        const data = response.data;
        return new ClientDTO(data);
    }
}