import {ReactElement} from "react";
import {Page} from "./Page";
import {AppBarComponent} from "./AppBarComponent";
export interface SideBarPageProps {
    component: ReactElement,
    pageTitle: string,
    titleBarTitle?: string,
}

export function AppBarPage(props: SideBarPageProps) {
    return (
        <Page
            title={props.pageTitle}
            component={
                <AppBarComponent
                    title={props.titleBarTitle ?? props.pageTitle}
                    component={props.component}
                />
            }
        />
    )
}
