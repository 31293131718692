import {ClientArtistDTO} from "../models/ClientArtistDTO";
import axiosInstance from "../network/AxiosInstance";
import {Page} from "../pagination/Page";

export class ClientArtistService {
    static async finAllArtistsForClientPaged(page: number, size: number, id: number, artist: string | undefined): Promise<Page<ClientArtistDTO>>{
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString()
        })

        if(artist){
            params.append("artist", artist);
        }

        const response = await axiosInstance.get<Page<ClientArtistDTO>>(`/rest/client/client-artist/${id}/all-paged`, {params: params})
        const data = response.data;
        return new Page<ClientArtistDTO>(
            data.content.map(clientArtist => new ClientArtistDTO(clientArtist)),
            data.totalElements
        );
    }

    static async findAllOurs(id: number): Promise<ClientArtistDTO[]> {
        const response = await axiosInstance.get<ClientArtistDTO[]>(`/rest/client/client-artist/${id}/find-all-ours`);
        const data = response.data;
        return data.map(clientArtist => new ClientArtistDTO(clientArtist));
    }
}
