type WhereFunction<T> = {
    (item: T): boolean;
}

export class CollectionUtils {
    static containsAll<T>(list: T[], target: T[]): boolean {
        const setOfAll = new Set<T>(list);

        for (const value of target) {
            if (!setOfAll.has(value)) {
                return false;
            }
        }

        return true;
    }

    static containsAny<T>(list: T[], target: T[]): boolean {
        const setOfAll = new Set<T>(list);

        for (const value of target) {
            if (setOfAll.has(value)) {
                return true;
            }
        }

        return false;
    }

    static shiftToEnd<T>(items: T[], where: WhereFunction<T>) {
        const indexesToBeShifted: number[] = [];

        // Iterate in reverse order so that the shifting order is consistent
        for (let idx = items.length - 1;idx >= 0;--idx) {
            if (where(items[idx])) {
                indexesToBeShifted.push(idx);
            }
        }

        for (const idx of indexesToBeShifted) {
            const item = items.splice(idx, 1);
            items.push(item[0]);
        }
    }
}
