import React from 'react';
import logo from './logo.svg';
import './App.css';
import {createTheme, ThemeProvider} from "@mui/material";
import {createRouter} from "./router/Router";
import {RouterProvider} from "react-router-dom";
import {Localization} from "./localization/Localization";

const theme = createTheme({
  palette:{
    primary:{
      main : "#e4e4e2"
    },
    secondary : {
      main : "#fff"
    }
  },
  typography:{
    fontFamily : 'Tahoma, sans-serif',
    fontSize : 13,
    fontWeightLight : 400,
    fontWeightRegular : 500,
    fontWeightMedium : 600,
    fontWeightBold : 700
  }
})

function App() {
  Localization.initialize();

  return (
      <ThemeProvider theme = {theme}>
        <div className="App">
          <RouterProvider router={createRouter()}/>
        </div>
      </ThemeProvider>
  );
}

export default App;
