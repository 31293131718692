import {TotalListeningReportDTO} from "./TotalListeningReportDTO";

export class TotalListeningReportForAccountGroupDTO extends TotalListeningReportDTO{
    accountGroupName: string;
    accountGroupId: number;
    dateCreated: string;

    constructor(json: any) {
        super(json);
        this.accountGroupName = json.accountGroupName;
        this.accountGroupId = json.accountGroupId;
        this.dateCreated = json.dateCreated;
    }
}
