export class TotalListeningReportDTO{
    startDate: string;
    type: string;
    listeningCount: number;
    accountNumber: number;

    constructor(json: any) {
        this.startDate = json.startDate;
        this.type = json.type;
        this.listeningCount = json.listeningCount;
        this.accountNumber = json.accountNumber;
    }
}
