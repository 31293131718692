import Chart from "react-apexcharts";
import Typography from "@mui/material/Typography";
import {strings} from "../localization/Localization";
import * as React from "react";
import {ApexOptions} from "apexcharts";
import {ViewsCount} from "../models/ViewsCount";

interface props {
    series: any;
    views: any;
    streamsFilter: any;
    text: any;
    categories: any;
    tickAmount: any;
    type?: 'area' | 'bar';
    horizontal?: boolean;
    showDataLabels?: boolean;
    xaxisType?: "category" | "datetime" | "numeric" | undefined;
}

export function ChartComponent({series, views, streamsFilter, text, categories, tickAmount, type = 'area', horizontal = false, showDataLabels = false, xaxisType} : props) {
    let chartHeight = 500;

    if (type === 'bar') {
        const barHeight = 40;
        chartHeight = Math.max(series.length * barHeight, 200);
    }

    const options: ApexOptions = {
        chart: {
            height: chartHeight,
            type: type,
            zoom: {
                enabled: false
            },
            animations: {
                enabled: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: horizontal,
                barHeight: "100%"
            }
        },
        dataLabels: {
            enabled: showDataLabels,
            formatter: function (val) {
                return val.toString();
            },
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: text,
            align: 'center',
            style: {
                fontSize: '24'
            }
        },
        grid: {
            row: {
                colors: ['#e5e5e5', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,

            },
            borderColor: '#b4b2b2',

        },
        xaxis: {
            categories: categories,
            labels: {
                show: true,
            },
            tickAmount: tickAmount,
            type: xaxisType,
        },
        legend: {
            show: true
        },
    };

    return(
        <div id="chart" className="chartTextDiv" style={{justifyContent: "space-between"}}>
            <div className="chartDiv" style={{width: "80%"}}>
                <Chart options={options} series={series} type={type} height={chartHeight}/>
            </div>
            <Typography className="totalStreams"
                        style={{marginRight: "120px", fontSize: "20px"}}>
                <b>{streamsFilter === ViewsCount.STAT_STREAMS ? strings.totalStreams : streamsFilter === ViewsCount.STAT_ACCOUNTS ? strings.totalAccounts : strings.totalUniqueListeners}</b>
                <Typography
                    style={{fontSize: "24px"}}>{views}</Typography>
            </Typography>
        </div>    )
}
