export class ClientArtistDTO{
    id: number;
    clientId: number;
    artist: string;
    isOurs: boolean;


    constructor(json: any) {
        this.id = json.id;
        this.clientId = json.clientId;
        this.artist = json.artist;
        this.isOurs = json.isOurs;
    }
}
