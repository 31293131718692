import {createBrowserRouter} from "react-router-dom";
import {Routes} from "./Routes";
import {GuardedRoute} from "./guards/GuardedRoute";
import {AuthGuard} from "./guards/AuthGuard";
import {ErrorRoute} from "../pages/error/ErrorRoute";
import {Login} from "../pages/login/Login";
import {NonAuthGuard} from "./guards/NonAuthGuard";
import {DashboardPage} from "../pages/dashboard/DashboardPage";
import {SummaryReportPage} from '../pages/summary-report/SummaryReportPage';

export function createRouter(){
    return createBrowserRouter([
        {
            path: Routes.HOME,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<DashboardPage/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },
        {
            path: Routes.LOGIN,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<Login/>}
                />
            ),
            errorElement: <ErrorRoute/>,

        },
        {
            path: Routes.SUMMARY_REPORT,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<SummaryReportPage/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },
    ])
}
