import {Page} from "../../components/Page";
import {FormEvent, useState} from "react";
import {AppController} from "../../controllers/AppController";
import {useNavigate} from "react-router-dom";
import './Login.css';
import {Localization, strings} from "../../localization/Localization";
import {Button, Link, TextField} from "@mui/material";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {Routes} from "../../router/Routes";
import {VisibilityEye} from "../../components/VisibilityEye";

export function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    async function login(event: FormEvent): Promise<void> {
        event.preventDefault();
        try {
            return await AppController.login(navigate, email, password);
        } catch (e) {
            alert('Invalid username or password.');
        }
    }

    function handleResetPassword() {
        navigate(Routes.RESET_PASSWORD);
    }

    function setLanguage(language: string): void {
        Localization.setLanguage(language);
    }

    return (
        <Page title={strings.login}
              component={
                  <CenteredCardLayout minWidth={"30%"} maxWidth={"30%"} style={{marginTop: "20px"}}>
                      <form onSubmit={login}>
                          <div style={{textAlign: "center", marginTop: "20px"}}><b>{strings.sBotsAnalytics}</b></div>
                              <div style={{marginTop: "20px"}}>
                                  <TextField
                                      onChange={(event) => setEmail(event.target.value)}
                                      className="mb-3"
                                      sx = {{width:'100%'}}
                                      fullWidth
                                      required
                                      id="email"
                                      label="Email"
                                  />
                                  <VisibilityEye
                                      onChange={setPassword}
                                      label={"Password *"}
                                      style={{marginTop: "20px", width: "100%"}}
                                  />
                                  <br/>
                                  <Button variant="contained" style={{float: "right", marginTop: "20px", marginBottom: "20px"}}
                                          type="submit" onClick={login}> {strings.login}</Button>
                              </div>
                      </form>
                  </CenteredCardLayout>
              }
        />
    );
}
