export class ViewsCount {
    static readonly STAT_STREAMS = 1;
    static readonly STAT_UNIQUE_LISTENERS = 2;
    static readonly STAT_ACCOUNTS = 3;

    viewCount: number;
    uniqueListeners: number;
    mobileUniqueListeners: number;
    accounts: number;

    constructor(json: any) {
        this.viewCount = json.viewCount;
        this.uniqueListeners = json.uniqueListeners;
        this.mobileUniqueListeners = json.mobileUniqueListeners;
        this.accounts = json.accounts;
    }

    getStat(stat: number): number {
        if (stat === ViewsCount.STAT_STREAMS) {
            return this.viewCount;
        } else if (stat === ViewsCount.STAT_UNIQUE_LISTENERS) {
            return this.uniqueListeners;
        } else if (stat === ViewsCount.STAT_ACCOUNTS) {
            return this.accounts;
        }

        return 0;
    }
}