import axiosInstance from "../network/AxiosInstance";
import {GroupedViewsCountDTO} from "../models/GroupedViewsCountDTO";
import {TotalListeningReportDTO} from "../models/TotalListeningReportDTO";
import {TotalListeningReportForAccountGroupDTO} from '../models/TotalListeningReportForAccountGroupDTO';
import {Page} from "../pagination/Page";

export class AnalyticsService {
    static async getListenedArtistsViewsCountPerDayGrouped(id: number | undefined, dateFrom: string | null | undefined, dateTo: string | null | undefined,
                                                           groupBy: string, countryCode?: string | undefined, serviceId?: number | undefined, artist?: string): Promise<GroupedViewsCountDTO>{
        let params = new URLSearchParams();

        params.append("fetchScrapedData", "false");

        if(dateFrom){
            params.append("dateFrom", dateFrom);
        }

        if(dateTo){
            params.append("dateTo", dateTo);
        }

        if(groupBy){
            params.append("groupBy", groupBy)
        }

        if(countryCode){
            params.append("countryCode", countryCode);
        }

        if(serviceId){
            params.append("serviceId", serviceId.toString());
        }

        if(artist){
            params.append("artist", artist);
        }

        const request = {
            params: params,
        }

        const response = await axiosInstance.get<GroupedViewsCountDTO>(
            `/rest/client/client/${id}/listened-artists-views-count-per-day-grouped`, request
        )

        const data = response.data;
        return new GroupedViewsCountDTO(data);
    }

    static async findDailyListeningReports(id: number | undefined,
                                           dateFrom: string | null | undefined, dateTo: string | null | undefined,
                                           serviceId?: number | undefined): Promise<TotalListeningReportDTO[]>{
        let params = new URLSearchParams();

        if(dateFrom && dateFrom !== 'Invalid Date'){
            params.append("dateFrom", dateFrom);
        }

        if(dateTo && dateTo !== 'Invalid Date'){
            params.append("dateTo", dateTo);
        }

        if(serviceId){
            params.append("serviceId", serviceId.toString());
        }

        const request = {
            params: params,
        }

        const response = await axiosInstance.get<TotalListeningReportDTO[]>(
            `/rest/client/client/${id}/find-daily-listening-reports`, request
        )

        const data = response.data;

        return data.map(item => new TotalListeningReportDTO(item));
    }

    static async findDailyListeningReportsForAccountGroup(id: number | undefined,
                                                          date: string | null | undefined,
                                                          serviceId?: number | undefined): Promise<TotalListeningReportForAccountGroupDTO[]>{
        let params = new URLSearchParams();

        if(date && date !== 'Invalid Date'){
            params.append("date", date);
        }

        if(serviceId){
            params.append("serviceId", serviceId.toString());
        }

        const request = {
            params: params,
        }

        const response = await axiosInstance.get<TotalListeningReportForAccountGroupDTO[]>(
            `/rest/client/client/${id}/find-daily-listening-reports-for-account-group`, request
        )

        const data = response.data;

        return data.map(item => new TotalListeningReportForAccountGroupDTO(item));
    }

}
