import {GroupedViewsCountPerDayDTO} from "./GroupedViewsCountPerDayDTO";

export class GroupedViewsCountDTO{
    botViews: GroupedViewsCountPerDayDTO[];
    scrapedViews: GroupedViewsCountPerDayDTO[];

    constructor(json: any) {
        this.botViews = json.botViews?.map((bot: any) => new GroupedViewsCountPerDayDTO(bot)) ?? [];
        this.scrapedViews = json.scrapedViews?.map((scraped: any) => new GroupedViewsCountPerDayDTO(scraped)) ?? [];
    }
}