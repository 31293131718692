import LocalizedStrings from "react-localization";
import {languageStrings} from "./LanguageStrings";
import {StorageService} from "../storage/StorageService";

export const strings = new LocalizedStrings(languageStrings)

export class Localization {
    public static LANGUAGE_EN = "en";

    static setLanguage(language: string, reload: boolean = true): void {
        strings.setLanguage(language);
        StorageService.setLanguage(language);

        if (reload) {
            window.location.reload();
        }
    }

    static initialize(): void {
        const language = StorageService.hasLanguage() ? StorageService.getLanguage()! : this.LANGUAGE_EN;
        this.setLanguage(language, false);
    }
}
