export class CountryDTO{
    id: number;
    commonName: string;
    officialName: string;
    countryCode2: string;
    countryCode3: string;
    flag: string;


    constructor(json: any) {
        this.id = json.id;
        this.commonName = json.commonName;
        this.officialName = json.officialName;
        this.countryCode2 = json.countryCode2;
        this.countryCode3 = json.countryCode3;
        this.flag = json.flag;
    }
}