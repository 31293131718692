export class ServiceDTO{
    id:number;
    label: string;
    key:string;
    constructor(json:{id:number, label:string, key:string}){
        this.id=json.id;
        this.label=json.label;
        this.key=json.key;
    }

}