import {AppBarPage} from "../../components/AppBarPage";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import * as React from "react";
import {
    Button, Checkbox,
    Collapse,
    FormControl, FormControlLabel,
    Grid, InputLabel, ListItemText,
    Paper,
    Select, SelectChangeEvent,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow
} from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';
import {useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {GroupedViewsCountPerDayDTO} from "../../models/GroupedViewsCountPerDayDTO";
import {AnalyticsService} from "../../services/AnalyticsService";
import dayjs from "dayjs";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {StorageService} from "../../storage/StorageService";
import {ViewsCount} from "../../models/ViewsCount";
import {ClientDTO} from "../../models/ClientDTO";
import {ClientService} from "../../services/ClientService";
import {ClientArtistService} from "../../services/ClientArtistService";
import {PaginatedSelect} from "../../components/PaginatedSelect";
import {ClientArtistDTO} from "../../models/ClientArtistDTO";
import {Page} from "../../pagination/Page";
import {ServicesService} from "../../services/ServicesService";
import {ServiceDTO} from "../../models/ServiceDTO";
import {CountryDTO} from "../../models/CountryDTO";
import {CountryService} from "../../services/CountryService";
import IconButton from "@mui/material/IconButton";
import {Clear} from "@mui/icons-material";
import {strings} from "../../localization/Localization";
import Box from "@mui/material/Box";
import "../../App.css"
import {ChartComponent} from "../../components/ChartComponent";
import {CollectionUtils} from "../../utils/CollectionUtils";
import {FullPageLoadingCircle} from "../../components/FullPageLoadingCircle";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {useNavigate} from "react-router-dom";
import {Routes} from "../../router/Routes";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

class DataItem {
    constructor(
        public key: string,
        public label: string,
        public botStat: number,
        public scrapedStat: number,
        public mobileUniqueListeners: number,
    ) {};
}

interface SortConfig {
    key: keyof DataItem | null;
    direction: 'ascending' | 'descending' | null;
}

export function DashboardPage() {
    const SERVICE = "service";
    const COUNTRY = "country";
    const ARTIST = "artist";

    const TODAY = 0;
    const YESTERDAY = 1;
    const LAST_7_DAYS = 7;
    const LAST_14_DAYS = 14;
    const LAST_30_DAYS = 30;
    const LAST_90_DAYS = 90;
    const LAST_12_MONTHS = 365;

    const OTHERS = "Others";
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: null });

    const id = StorageService.getJWTPayload()?.clientId;
    const [clicked, setClicked] = useState<boolean>(false);
    const [groupBy, setGroupBy] = useState<string>(SERVICE);
    const [viewsPerDayGrouped, setViewsPerDayGrouped] = useState<GroupedViewsCountPerDayDTO[]>([]); //bot Views
    const [scrapedViewsPerDayGrouped, setScrapedViewsPerDayGrouped] = useState<GroupedViewsCountPerDayDTO[]>([]); //bot Views
    const [streamsFilter, setStreamsFilter] = useState<number>(ViewsCount.STAT_STREAMS);
    const [periodFilter, setPeriodFilter] = useState<number>(LAST_7_DAYS);
    const [client, setClient] = useState<ClientDTO | undefined>(undefined);
    const [serviceIdFilter, setServiceIdFilter] = useState<number | undefined>(undefined);
    const [countryIdFilter, setCountryIdFilter] = useState<CountryDTO | undefined>(undefined);
    const [artistFilter, setArtistFilter] = useState<ClientArtistDTO | undefined>(undefined);
    const [services, setServices] = useState<ServiceDTO[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterValues, setFilterValues] = useState<string[]>([]); // Selected filter values
    const [oursSelected, setOursSelected] = useState<boolean>(false);
    const [notOursSelected, setNotOursSelected] = useState<boolean>(false);
    const [oursArtists, setOursArtists] = useState<ClientArtistDTO[]>([]);

    const navigate = useNavigate();

    const handleSummaryReportClick = () => {
        navigate(Routes.SUMMARY_REPORT);
    };

    const handleFilterValueChange = (event: SelectChangeEvent<typeof filterValues>) => {
        setFilterValues(event.target.value as string[]);
    };

    const requestSort = (key: keyof DataItem) => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (columnKey: keyof DataItem) => {
        if (sortConfig.key === columnKey) {
            return sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
        }
        return null;
    };

    function mapViewsCount(items: GroupedViewsCountPerDayDTO[]): Map<string, GroupedViewsCountPerDayDTO> {
        const map = new Map<string, GroupedViewsCountPerDayDTO>();

        for (const item of items) {
            map.set(item.key, item);
        }

        return map;
    }

    const viewsCountPerDayMap = mapViewsCount(viewsPerDayGrouped);
    const scrapedViewsPerDayMap = mapViewsCount(scrapedViewsPerDayGrouped);

    function handleClickedChange() {
        if(clicked){
            setClicked(false)
        } else {
            setClicked(true)
        }
    }

    const viewersOptionDropdown = [
        {
            label: "Streams",
            id: ViewsCount.STAT_STREAMS,
        },
        {
            label: "Unique listens",
            id: ViewsCount.STAT_UNIQUE_LISTENERS,
        },
        {
            label: "Accounts",
            id: ViewsCount.STAT_ACCOUNTS,
        }
    ]

    const typeOfFilterOptionDropdown = [
        {
            label: "Service",
            id: SERVICE
        },
        {
            label: "Country",
            id: COUNTRY
        },
        {
            label: "Artist",
            id: ARTIST
        }
    ]

    const periodFilterOptionDropdown = [
        {
            label: "Today",
            id: TODAY
        },
        {
            label: "Yesterday",
            id: YESTERDAY
        },
        {
            label: "Last 7 days",
            id: LAST_7_DAYS
        },
        {
            label: "Last 14 days",
            id: LAST_14_DAYS
        },
        {
            label: "Last 30 days",
            id: LAST_30_DAYS
        },
        {
            label: "Last 90 days",
            id: LAST_90_DAYS
        },
        {
            label: "Last 12 months",
            id: LAST_12_MONTHS
        }
    ]
    const handleStreamsFilter = (event: SelectChangeEvent) => {
        setStreamsFilter(+event.target.value);
    }

    const handleServiceFilter = (event: SelectChangeEvent) => {
        setServiceIdFilter(+event.target.value);
    }

    const handleCountryFilter = (event: CountryDTO | undefined) => {
        setCountryIdFilter(event);
    }

    const handleArtistFilter = (event: ClientArtistDTO | undefined) => {
        setArtistFilter(event);
    }

    const handleTypeFilter = (event: SelectChangeEvent) => {
        setGroupBy(event.target.value);
        setFilterValues([]);
    }

    const handlePeriodFilter = (event: SelectChangeEvent) => {
        setPeriodFilter(+event.target.value);
    }

    useEffect(() => {
        if (id) {
            ClientService.findById(id).then(client => {
                setClient(client);
            })
        }

        async function getAllServices() {
            const services = await ServicesService.getAllServices();
            setServices(services);
        }

        getAllServices()
    }, []);

    function getDateFrom(){
        const date = new Date();
        date.setDate(date.getDate() - periodFilter);
        return date;
    }

    function getDateTo(){
        const date = new Date();
        if(periodFilter === TODAY)
        date.setDate(date.getDate() + 1);
        return date;
    }

    useEffect(() => {
        async function getListenedArtistsViewsCountPerDayGrouped(){
            setIsLoading(true)
            const response = await AnalyticsService.getListenedArtistsViewsCountPerDayGrouped(id, DateTimeUtils.formatDateDayJs(dayjs(getDateFrom())), DateTimeUtils.formatDateDayJs(dayjs(getDateTo())), groupBy, countryIdFilter?.countryCode2, serviceIdFilter, artistFilter?.artist)
            setViewsPerDayGrouped(response.botViews);
            setScrapedViewsPerDayGrouped(response.scrapedViews);
            response.botViews.map((e) => setGroupBy(e.groupBy))
            // response.map((s) => setServiceIdFilter(s.serviceId))
            setIsLoading(false)
        }

        getListenedArtistsViewsCountPerDayGrouped();
    }, [periodFilter, groupBy, serviceIdFilter, countryIdFilter, artistFilter])

    function getStatistic(viewsCount: ViewsCount) {
        if (streamsFilter === ViewsCount.STAT_STREAMS) {
            return viewsCount.viewCount;
        } else if (streamsFilter === ViewsCount.STAT_UNIQUE_LISTENERS) {
            return viewsCount.uniqueListeners;
        } else if (streamsFilter === ViewsCount.STAT_ACCOUNTS) {
            return viewsCount.accounts;
        }

        return 0;
    }

    function getDataItems(): DataItem[] {
        const keys = [
            ... new Set<string>([
                ...viewsPerDayGrouped.map(item => item.key),
                ...scrapedViewsPerDayGrouped.map(item => item.key)
            ])
        ];

        keys.sort();

        if (groupBy === ARTIST) {
            CollectionUtils.shiftToEnd(keys, item => item === OTHERS);
        }

        const dataItems: DataItem[] = [];

        for (const key of keys) {
            const botItem = viewsCountPerDayMap.has(key) ? viewsCountPerDayMap.get(key) : undefined;
            const scrapedItem = scrapedViewsPerDayMap.has(key) ? scrapedViewsPerDayMap.get(key) : undefined;

            if (botItem !== undefined || scrapedItem !== undefined) {
                dataItems.push(new DataItem(
                    key,
                    botItem?.label ?? scrapedItem?.label ?? "",
                    botItem?.total?.getStat(streamsFilter) ?? 0,
                    scrapedItem?.total?.getStat(streamsFilter) ?? 0,
                    botItem?.total?.mobileUniqueListeners ?? 0,
                ))
            }
        }

        return dataItems;
    }

    const dataItems = getDataItems();

    const series = viewsPerDayGrouped?.map((series) => {
        return {
            name: series.label,
            data: series.viewsCountPerDay.map((views) => getStatistic(views))
        }
    })

    // const seriesScraped = scrapedViewsPerDayGrouped?.map((series) => {
    //     return {
    //         name: series.label,
    //         data: series.viewsCountPerDay.map((views) => getStatistic(views))
    //     }
    // })

    function getChartCategories() {
        if (viewsPerDayGrouped.length === 0) {
            return [];
        }

        return viewsPerDayGrouped[0]?.viewsCountPerDay.map((item) => item.date);
    }

    // function getScrapedChartCategories() {
    //     if (scrapedViewsPerDayGrouped.length === 0) {
    //         return [];
    //     }
    //
    //     return scrapedViewsPerDayGrouped[0]?.viewsCountPerDay.map((item) => item.date);
    // }

    const tickAmountChange = (tickAmount: number) => {
        if(periodFilter === TODAY){
            tickAmount = 1;
        } else if(periodFilter === YESTERDAY){
            tickAmount= 1
        } else if(periodFilter === LAST_7_DAYS){
            tickAmount = 6;
        } else if(periodFilter === LAST_14_DAYS){
            tickAmount = 13;
        } else if(periodFilter === LAST_30_DAYS || periodFilter === LAST_90_DAYS || periodFilter === LAST_12_MONTHS){
            tickAmount = 29
        }

        return tickAmount;
    }

    const sortedDataItems = [...dataItems].sort((a, b) => {
        if (!sortConfig.key) {
            return  0; // No sort config, so no sorting applied
        }
        if (typeof a[sortConfig.key] === 'number' && typeof b[sortConfig.key] === 'number') {
            // Both values are numbers, so we can compare them directly
            return sortConfig.direction === 'ascending'
                ? (a[sortConfig.key] as number) - (b[sortConfig.key] as number)
                : (b[sortConfig.key] as number) - (a[sortConfig.key] as number);
        }
        // If the values are not numbers, fall back to string comparison
        return sortConfig.direction === 'ascending'
            ? String(a[sortConfig.key]).localeCompare(String(b[sortConfig.key]))
            : String(b[sortConfig.key]).localeCompare(String(a[sortConfig.key]));
    });

    const filteredDataItems = filterValues.length >  0
        ? sortedDataItems.filter(item => filterValues.includes(item.key))
        : sortedDataItems; // Return all items if no filterValues are selected

    useEffect(() => {
        async function fetchOursArtists() {
            if (id) {
                const ours = await ClientArtistService.findAllOurs(id);
                setOursArtists(ours);
            } else {
                setOursArtists([]);
            }
        }

        fetchOursArtists();
    }, []);

    const isOursArtist = (artistLabel: string) => {
        return oursArtists.some(artist => artist.artist === artistLabel);
    };

    const handleOursCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setOursSelected(event.target.checked);

        if (event.target.checked) {
            setNotOursSelected(false);

            const oursKeys = sortedDataItems
                .filter(item => isOursArtist(item.label))
                .map(item => item.key);

            setFilterValues(oursKeys);
        } else {
            setFilterValues([]);
        }
    };

    const handleNotOursCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotOursSelected(event.target.checked);

        if (event.target.checked) {
            setOursSelected(false);

            const notOursKeys = sortedDataItems
                .filter(item => !isOursArtist(item.label))
                .map(item => item.key);

            setFilterValues(notOursKeys);
        } else {
            setFilterValues([]);
        }
    };

    const handleRemoveSelectedOptions = () => {
        setFilterValues([]);
        setOursSelected(false);
        setNotOursSelected(false);
    }

    return(
        <>
            <AppBarPage pageTitle={strings.analytics} component={
                <>
                    <FullPageLoadingCircle
                        loading={isLoading}
                    />
                  <AppBar position="absolute" sx={{marginTop: "65.5px", minHeight: "65.5px" }} color={"secondary"}>
                    <Toolbar>
                        <Button
                            onClick={handleSummaryReportClick}
                            sx={{
                                marginLeft: "20px",
                                cursor: "pointer",
                                fontWeight: "bold",
                                color: "black",
                                backgroundColor: "#efefef",
                                width: "10%",
                                '&:hover': {
                                    backgroundColor: "lightgray",
                                },
                            }}
                        >
                            {strings.summaryReport}
                        </Button>
                        <Button onClick={handleClickedChange} color={"success"} variant={"contained"}
                                sx={{marginLeft: "auto", borderRadius: "50px"}} size={"large"}>
                            <TuneIcon sx={{paddingRight: "5px"}}
                                      fontSize={"medium"}/>{clicked ? strings.hideFilters : strings.filterBy}</Button>
                    </Toolbar>
                    <Collapse in={clicked} timeout="auto" unmountOnExit>
                        <Grid sx={{height: "100px"}}>
                            <FormControl variant="outlined"
                                         style={{marginRight: "50px", width: "15%", textAlign: "left"}}>
                                <InputLabel id="serviceFilter">{strings.service}</InputLabel>
                                <Select labelId="serviceFilter"
                                        label={strings.service}
                                        value={serviceIdFilter?.toString() ?? ""}
                                        onChange={handleServiceFilter}
                                        endAdornment={serviceIdFilter && (
                                            <IconButton style={{marginRight: "20px"}}
                                                        onClick={() => setServiceIdFilter(undefined)}
                                            >
                                                <Clear/>
                                            </IconButton>
                                        )}
                                        sx={{
                                            color: "black",
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(112,111,111,0.25)',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'secondary',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'black', borderWidth: "2px"
                                            },
                                            '.MuiSvgIcon-root ': {
                                                fill: "black !important",
                                            }
                                        }}
                                >
                                    {services?.map((filter) => (
                                        <MenuItem disableRipple key={filter.id} value={filter.id}>
                                            {filter.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" style={{marginRight: "50px", width: "15%"}}>
                                <PaginatedSelect<CountryDTO>
                                    value={countryIdFilter}
                                    onChange={handleCountryFilter}
                                    label={strings.country}
                                    valueMapper={(item) => item.id.toString()}
                                    keyMapper={(item) => item.id.toString()}
                                    itemMapper={(item) => <>{item.commonName}</>}
                                    labelMapper={(item) => item.commonName}
                                    dataFetcher={(page, size, filter) => {
                                        if (id) {
                                            return CountryService.findAllCountriesPaged(
                                                page,
                                                size,
                                                filter
                                            );
                                        } else {
                                            return Promise.resolve(new Page<CountryDTO>(
                                                [],
                                                0
                                            ));
                                        }
                                    }}/>
                            </FormControl>
                            <FormControl variant="outlined" style={{width: "15%"}}>
                                <PaginatedSelect<ClientArtistDTO>
                                    value={artistFilter}
                                    onChange={handleArtistFilter}
                                    label={strings.artist}
                                    valueMapper={(item) => item.id.toString()}
                                    keyMapper={(item) => item.id.toString()}
                                    itemMapper={(item) => <>{item.artist}</>}
                                    labelMapper={(item) => item.artist}
                                    dataFetcher={(page, size, filter) => {
                                        if (id) {
                                            return ClientArtistService.finAllArtistsForClientPaged(
                                                page,
                                                size,
                                                id,
                                                filter
                                            );
                                        } else {
                                            return Promise.resolve(new Page<ClientArtistDTO>(
                                                [],
                                                0
                                            ));
                                        }
                                    }}/>
                            </FormControl>
                        </Grid>
                    </Collapse>
                </AppBar>
                    <Box>
                    <div style={{marginTop: "80px"}}>
                        <Typography
                            style={{fontSize: "3rem"}}><b>{client?.name ? `Welcome, ${client.name}!` : "Welcome!"}</b></Typography>
                        <br/>
                        <Typography style={{
                            fontSize: "1rem",
                        }}>{strings.welcomeAnalytics}<br/>{strings.infoAnalytics}</Typography>

                        {streamsFilter === ViewsCount.STAT_UNIQUE_LISTENERS && <Typography style={{
                            fontSize: "1rem",
                            marginTop: "40px",
                        }}>
                            {strings.uniqueListensExplanation}
                        </Typography>
                        }

                        <Box style={{
                            marginBottom: "100px"
                        }}></Box>
                    </div>
                    <div className={"mainDiv"} style={{
                        flexDirection: "row",
                        marginLeft: "40px",
                        width: "77.5%",
                        justifyContent: "space-between",
                        marginBottom: "25px"
                    }}>
                            <div className="div1" style={{display: "flex", flexDirection: "row"}}>
                                <FormControl className="formControl" style={{float: "right", display: "flex", flexDirection: "row"}} variant="outlined" fullWidth>
                                <Typography style={{
                                    marginRight: "20px",
                                    fontSize: "18px",
                                    marginTop: "10px"
                                }}>{strings.view}</Typography>
                                    <Select className="dropdown-menu"
                                            value={`${streamsFilter}`}
                                            onChange={handleStreamsFilter}
                                            style={{
                                                border: "black",
                                                borderStyle: "solid",
                                                borderWidth: "1.5px",
                                                borderRadius: "1px",
                                                backgroundColor: "White"
                                            }}
                                    >
                                        {viewersOptionDropdown?.map((filter) => (
                                            <MenuItem disableRipple key={filter.id} value={filter.id}>
                                                {filter.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="div2" style={{display: "flex", flexDirection: "row"}}>
                                <FormControl className="formControl" variant="outlined" style={{float: "right", display: "flex", flexDirection: "row"}} fullWidth>

                                <Typography style={{
                                    marginRight: "20px",
                                    fontSize: "18px",
                                    marginTop: "10px"
                                }}>{strings.by}</Typography>
                                    <Select value={groupBy} className="dropdown-menu2"
                                            onChange={handleTypeFilter}
                                            style={{
                                                border: "black",
                                                borderStyle: "solid",
                                                borderWidth: "1.5px",
                                                borderRadius: "1px",
                                                backgroundColor: "White"
                                            }}
                                    >
                                        {typeOfFilterOptionDropdown?.map((type) => (
                                            <MenuItem disableRipple key={type.id} value={type.id}>
                                                {type.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="div3" style={{display: "flex", flexDirection: "row"}}>
                                <FormControl className="formControl" variant="outlined" style={{float: "right", display: "flex", flexDirection: "row"}} fullWidth>

                                <Typography style={{
                                    marginRight: "20px",
                                    fontSize: "18px",
                                    marginTop: "10px"
                                }}>{strings.period}</Typography>
                                    <Select className="dropdown-menu3"
                                            value={periodFilter.toString()}
                                            onChange={handlePeriodFilter}
                                            style={{
                                                border: "black",
                                                borderStyle: "solid",
                                                borderWidth: "1.5px",
                                                borderRadius: "1px",
                                                backgroundColor: "White"
                                            }}
                                    >
                                        {periodFilterOptionDropdown?.map((period) => (
                                            <MenuItem disableRipple key={period.id} value={period.id}>
                                                {period.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                    </div>
                    <ChartComponent series={series} views={viewsPerDayGrouped.reduce((views,
                        groupViews) => views + getStatistic(groupViews.total), 0)} streamsFilter={streamsFilter}
                        text={streamsFilter === ViewsCount.STAT_STREAMS ? strings.streamsBot : streamsFilter === ViewsCount.STAT_ACCOUNTS ? strings.accountsBot : strings.uniqueListenersBot} categories={getChartCategories()}
                        tickAmount={tickAmountChange(periodFilter)}
                        xaxisType="datetime"
                    />
                    {/*<ChartComponent series={seriesScraped} views={scrapedViewsPerDayGrouped.reduce((views,*/}
                    {/*    groupViews) => views + getStatistic(groupViews.total), 0)} streamsFilter={streamsFilter}*/}
                    {/*    text={streamsFilter === ViewsCount.STAT_STREAMS ? strings.streamsActual : strings.uniqueListenersActual} categories={getScrapedChartCategories()}*/}
                    {/*    tickAmount={tickAmountChange(periodFilter)}/>*/}

                    </Box>
                        <FormControl className="formControl" variant="outlined" style={{float: "right", display: "flex", flexDirection: "row"}} fullWidth>
                            <InputLabel>{strings.filter}</InputLabel>
                            <Select value={filterValues} onChange={handleFilterValueChange} style={{width: "20%", marginBottom: "10px"}}
                                    color={"info"} multiple
                                    renderValue={(selected) => (
                                        <div>
                                            {(selected as string[]).map(key => {
                                                const item = sortedDataItems.find(item => item.key === key);
                                                return item ? item.label : null;
                                            }).join(', ')}
                                        </div>
                                    )}
                                    sx={{
                                        color: "black",
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(112,111,111,0.25)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'secondary',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'black', borderWidth: "2px"
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: "black !important",
                                        },
                                        backgroundColor: "white", borderRadius: 0
                                    }}
                                    endAdornment={
                                        filterValues.length > 0 && (
                                            <IconButton
                                                onClick={handleRemoveSelectedOptions}
                                            >
                                                <Clear />
                                            </IconButton>
                                        )
                                    }>
                                {sortedDataItems.map((item) => (
                                    <MenuItem key={item.key} value={item.key}>
                                        <ListItemText primary={item.label} />
                                        <Checkbox
                                            color={"info"}
                                            checked={filterValues.includes(item.key)}
                                            onChange={() => {}} // Prevents the checkbox from being toggled by clicking on it
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                            <Box sx={{ marginLeft: "25px", marginTop: "5px" }}>
                            {groupBy === ARTIST && (
                                <>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={oursSelected}
                                                onChange={handleOursCheckboxChange}
                                                color="primary"
                                            />
                                        }
                                        label={strings.ours}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={notOursSelected}
                                                onChange={handleNotOursCheckboxChange}
                                                color="primary"
                                            />
                                        }
                                        label={strings.notOurs}
                                    />
                                </>
                            )}
                            </Box>
                        </FormControl>
                    <TableContainer component={Paper} style={{marginTop: "69px", borderRadius: "10px"}}>
                    <Table>
                        <TableHead>
                            <TableRow></TableRow>
                            <TableRow>
                                <TableCell width={"5%"} onClick={() => requestSort('label')}
                                           style={{fontFamily: "sans-serif", cursor: "pointer"}}>{groupBy === SERVICE ? strings.service : (groupBy === COUNTRY ? strings.country : (groupBy === ARTIST ? strings.artist : ""))}{getSortIcon('label')}</TableCell>
                                <TableCell width={"5%"}></TableCell>
                                <TableCell width={"5%"}></TableCell>
                                {groupBy === ARTIST && (
                                    <>
                                        <TableCell width={"5%"}></TableCell>
                                        <TableCell width={"20%"}>{strings.ours}</TableCell>
                                    </>
                                )}
                                <TableCell width={"20%"} onClick={() => requestSort('botStat')}
                                           style={{fontFamily: "sans-serif", cursor: "pointer"}}>{streamsFilter === ViewsCount.STAT_STREAMS ? strings.streamsBot : streamsFilter === ViewsCount.STAT_ACCOUNTS ? strings.accountsBot : strings.uniqueListenersBot}{getSortIcon('botStat')}</TableCell>
                                {/*<TableCell width={"20%"}*/}
                                {/*           style={{fontFamily: "sans-serif"}}>{streamsFilter === ViewsCount.STAT_STREAMS ? strings.streamsActual : strings.uniqueListenersActual}</TableCell>*/}
                                <TableCell width={"20%"}
                                           style={{fontFamily: "sans-serif", cursor: "pointer"}} onClick={() => requestSort('mobileUniqueListeners')}>Mobile unique listens (bot){getSortIcon('mobileUniqueListeners')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredDataItems.map((dataItem) => (
                                <TableRow key={groupBy + "-" + dataItem.key} hover={true} className="cursor-pointer">
                                    <TableCell style={{fontFamily: "sans-serif"}}>{dataItem.label}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    {groupBy === ARTIST && (
                                        <>
                                        <TableCell></TableCell>
                                        <TableCell width={"20%"}>
                                            {isOursArtist(dataItem.label) ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />}
                                        </TableCell>
                                        </>
                                    )}
                                    <TableCell
                                        style={{fontFamily: "sans-serif"}}>{dataItem.botStat}</TableCell>
                                    {/*<TableCell style={{fontFamily: "sans-serif"}}>{dataItem.scrapedStat}</TableCell>*/}
                                    <TableCell width={"20%"}>{dataItem.mobileUniqueListeners}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </>
            }/>
        </>
    )
}
