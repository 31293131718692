import {Backdrop, CircularProgress} from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import {strings} from "../localization/Localization";

interface FullPageLoadingCircleProps {
    loading: boolean,
    handleClose?: () => void,

}

export function FullPageLoadingCircle(props: FullPageLoadingCircleProps) {
    return (
        props.loading ?
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={props.loading}
            >
                <div className="flex-column">
                    <CircularProgress size={60} style={{color: "#3f51b5"}}/>
                </div>

            </Backdrop> : <></>
    )
}
